import axios from '@/plugins/axios'

/**
 * Upsert line ID
 */
const upsertCampaignLinePlatformId = async ({
  campaignId,
  platformId
}) => {
  try {
    const response = await axios.post(`admin/campaigns/${campaignId}/upsert-youtube-campaign-line`, {
      _method: 'PUT',
      platform_id: platformId
    })
    const { data } = response
    return data
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

export default {
  upsertCampaignLinePlatformId
}
