<template>
  <b-modal
    :id="`upsert-youtube-campaign-${campaignId}`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Ajustar campanha de YouTube</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>

    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <template v-if="loader && !campaign">
        <div class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
      </template>
      <template v-else-if="campaign">
        <p>
          <strong>Nome da campanha:</strong> {{ campaign.name }}<br/>
          <strong>Status:</strong> {{ campaign.status | formatCampaignStatus }}
        </p>
        <h2>Vídeo</h2>
        <p>
          <strong>Nome:</strong> {{ creative.name }}<br/>
          <strong>Link do vídeo:</strong> <a :href="`https://youtube.com/watch?v=${creative.youtube_id}`" target="_blank">{{ `https://youtube.com/watch?v=${creative.youtube_id}` }}</a><br/>
          <strong>ID do vídeo:</strong> {{ creative.youtube_id }}<br/>
          <strong>Headline:</strong> {{  creative.headline }}<br/>
          <strong>Descrição:</strong> {{ creative.body_text }}<br/>
          <strong>CTA:</strong> {{ creative.call_to_action }}<br/>
          <strong>URL:</strong> {{ creative.url }}
        </p>
        <h2>Cadastrar line</h2>
        <div>
          <a
            :href="`https://displayvideo.google.com/ng_nav/p/660702863/a/${campaign.advertiser.google_id}/c/${campaign.platform_id}/io/${currentPackage.platform_id}/explorerlis`"
            class="btn btn-sm btn-success"
          >
            Abrir DV360
          </a>
          <p class="mt-2">
            <strong>Nome da line:</strong> Line - {{ campaign.name }}
          </p>
        </div>
        <form @submit.prevent="submit" class="">
          <div class="form-group mb-3">
            <label class="form-label text-start">ID da line</label>
            <input type="text" class="form-control" placeholder="ID da linha" v-model.trim="form.line_id" />
          </div>
          <div class="mt-3 text-center">
            <Button
              type="submit"
              class="btn btn-primary"
              :loading="loader"
            >
              Cadastrar line
            </Button>
          </div>
        </form>
      </template>
    </div>
  </b-modal>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { parseErrorResponseToArray } from '@/utils'
import YouTubeCampaignsService from '@/modules/campaigns/services/youtube-campaigns-service'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'UpsertYouTubeCampaignModal',
  props: ['campaignId'],
  components: {
    AlertWraper,
    Button,
    Loader
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      campaign: null,
      form: {
        line_id: ''
      }
    }
  },
  computed: {
    /**
     * Get creative
    */
    creative () {
      if (!this.campaign) return null
      return this.campaign.creatives[0]
    },
    /**
     * Get current line
    */
    currentLine () {
      if (!this.campaign) return null
      return this.campaign.packages[0].lines[0]
    },
    /**
     * Get current package
    */
    currentPackage () {
      if (!this.campaign) return null
      return this.campaign.packages[0]
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.campaign = null
      this.content = null
      this.form.line_id = ''
    },
    /**
     * On modal shown
     */
    shown () {
      this.getCampaign()
    },
    /**
     * Get order
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.campaignId)
        this.campaign = campaign
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Submit
    */
    async submit () {
      this.loader = true
      this.content = null
      try {
        const campaign = await YouTubeCampaignsService.upsertCampaignLinePlatformId({
          campaignId: this.campaign.id,
          platformId: this.form.line_id
        })
        this.$emit('upserted', campaign)
        this.$bvModal.hide(`upsert-youtube-campaign-${this.campaign.id}`)
      } catch (error) {
        console.log(error)
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
